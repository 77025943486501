import { FC, createRef, memo } from 'react';
import styles from '../../../ExpensionMap.module.scss';
import cx from 'classnames';
import { useExpansionMapHelper } from '../../../helper';
import { Districts } from '../../../../../constants/districts';

export const Flittard: FC = memo(() => {
  const ref = createRef<SVGTextElement>();
  const {
    district,
    expansionStatusClass,
    isSelected,
    handleClick,
    handleHover,
  } = useExpansionMapHelper({
    district: Districts.Flittard,
    ref,
  });

  return (
    <>
      <g
        className={cx(styles.districtWrapper, {
          [styles.isSelected]: isSelected,
        })}
        id={district}
        onClick={handleClick}
        onMouseEnter={handleHover}
      >
        <path
          className={cx(styles[expansionStatusClass], styles.districtArea)}
          aria-label="Flittard"
          d="M681.4,283.4l2.1,0.2c-0.1,0.7-0.1,2-0.8,2.4c-1.5,0-3.1-0.2-4.5-0.4c2.3,2.3,7.2,56,7.8,60.4c0,0-10,58-10,58 l-23-2l-6-10v18l-44,10l-12-8l-12,6l-8-18l-19,4l-1.5-11.8c-1-8.1-0.5-16.3,1.5-24.2v0c2.6-10.4,6.7-20.4,12.2-29.6 c4.2-7.1,8.5-14.8,10.1-19.7c2.7,1.6,6.3,3.2,9.2,4.3c0,2.3,0.1,5.7-0.1,8c3.1-0.4,7.6-1.2,10.3-2.7c1.1,0.9,2.3-0.1,3.2-0.8 c0.8-0.3,2.4-1.1,3.1-1.6c0.1,0.3,0.4,1.3,0.7,1.4c1.6-0.7,3.7-0.2,5.2,0.5c1.1,0.2,3.1,0.2,4.2,0.2c5-2.4,7-3.1,12.2-0.6 c2.4,0.1,5.4-1.2,7.8-0.8c0.4,2.3,5.5,1.2,7.2,1.9l-1.3-9.3l2.2-0.4l-1.2-8.4c-0.4,0.1-2.4-0.3-2.8-0.4c4.9-4.8,15.9-9,21.9-12.2 l-0.5-1.5l0.9-1.2l1.9-0.6l2,0.1l7-3.1l3.6,0.1c0.2-2.7,0.5-6.8,2.1-9C675.6,282.8,678.9,283.1,681.4,283.4z"
        />
        <text
          className={styles.districtName}
          ref={ref}
          transform="matrix(1 0 0 1 595.7367 371.8832)"
        >
          Flittard
        </text>
      </g>
    </>
  );
});
